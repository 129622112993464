import axios from 'axios'
import { USER_LOADED, USER_LOADING, AUTH_FAIL, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, REGISTER_SUCCESS, OTP_FAIL, REGISTER_FAIL, LOGIN_MESSAGE } from './types'
import store from '../store'

export const updateLoginMsg = (loginMsg) => (dispatch) => {
    dispatch({
        type: LOGIN_MESSAGE,
        msg: loginMsg
    })
}

// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch) => {
    // User Loading
    dispatch({ type: USER_LOADING })
    let token = localStorage.getItem('token')
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
    }
    axios
        .get(process.env.REACT_APP_KC_API_URL + 'reb/user', config)
        .then((res) => {
            dispatch({
                type: USER_LOADED,
                payload: res.data[0],
            })
        })
        .catch((err) => {
            dispatch({
                type: AUTH_FAIL,
            })
        })
}

// LOGIN USER WITH PASSWORD
export const pwdLogin = (username, password) => (dispatch) => {
    // Request Body
    const body = JSON.stringify({ username, password })

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    }

    axios
        .post(process.env.REACT_APP_KC_API_URL + 'auth/login', body, config)
        .then((res) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            })
        })
        .catch((err) => {
            if (err.response && err.response.status === 400) {
                dispatch({
                    type: LOGIN_MESSAGE,
                    msg: "Incorrect Login Credentials"
                })
            }
            dispatch({
                type: LOGIN_FAIL,
            })
        })
}

// LOGIN USER WITH OTP
export const otpLogin = (phone, otp) => (dispatch) => {
    // Request Body
    const body = JSON.stringify({ phone, otp })

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    }

    axios
        .post(process.env.REACT_APP_KC_API_URL + 'auth/login', body, config)
        .then((res) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            })
        })
        .catch((err) => {
            if (err.response && err.response.status === 400) {
                dispatch({
                    type: LOGIN_MESSAGE,
                    msg: err.response.data.msg
                })
            }
            dispatch({
                type: LOGIN_FAIL,
            })
        })
}

// GET OTP FOR New User
export const verifyUserid = (phone, email, username) => (dispatch) => {
    const newuser = "true"
    const usertype  = "Rebellion"

    // Request Body
    const body = JSON.stringify({ newuser, phone, email, username, usertype })

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    }

    axios
        .post(process.env.REACT_APP_KC_API_URL + 'verify', body, config)
        .then((res) => {
            dispatch({
                type: LOGIN_MESSAGE,
                msg: "otp sent"
            })
        })
        .catch((err) => {
            if (err.response && err.response.status === 400) {
                dispatch({
                    type: LOGIN_MESSAGE,
                    msg: err.response.data.msg
                })
            }
            dispatch({
                type: OTP_FAIL,
            })
        })
}

// GET OTP FOR Existing User
export const getOtp = (userid) => (dispatch) => {
    const newuser = "false"
    const usertype = "Rebellion"

    // Request Body
    const body = JSON.stringify({ newuser, userid, usertype })

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    }

    axios
        .post(process.env.REACT_APP_KC_API_URL + 'verify', body, config)
        .then((res) => {
            dispatch({
                type: LOGIN_MESSAGE,
                msg: "otp sent"
            })
        })
        .catch((err) => {
            if (err.response && err.response.status === 400) {
                dispatch({
                    type: LOGIN_MESSAGE,
                    msg: err.response.data.msg
                })
            }
            dispatch({
                type: OTP_FAIL,
            })
        })
}

// REGISTER USER
export const register = (username, email, phone, password, name, otp) => (dispatch) => {
    // Request Body
    const body = JSON.stringify({ username, email, phone, password, name, otp })

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    }

    axios
        .post(process.env.REACT_APP_KC_API_URL + 'auth/rebregister', body, config)
        .then((res) => {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data,
            })
        })
        .catch((err) => {
            if (err.response && err.response.status === 400) {
                dispatch({
                    type: LOGIN_MESSAGE,
                    msg: err.response.data.msg
                })
            }
            dispatch({
                type: REGISTER_FAIL,
            })
        })
}

// LOGOUT USER
export const logout = () => (dispatch, getState) => {
    axios
        .post(process.env.REACT_APP_KC_API_URL + 'auth/logout', null, tokenConfig(getState))
        .then((res) => {
            dispatch({ type: 'CLEAR_LEADS' })
            dispatch({
                type: LOGOUT_SUCCESS,
            })
        })
        .catch((err) => {
        })
}

// Setup config with token - helper function
export const tokenConfig = () => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    }
    // Get token from state
    const token = store.getState().user.token
  
    // If token, add to headers config
    if (token) {
        config.headers['Authorization'] = `Token ${token}`
    }

    return config
}