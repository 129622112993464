import { connect } from "react-redux";
import { logout } from "../actions/user";
import { FaBars } from "react-icons/fa";
import "../styles/header.css";
import "../styles/dashboard.css";

const Header = ( { mobileMenuOpen, setMobileMenuOpen }) => {

  return (
    <header className="header" id="header">
      <div className='nav-bar' style={mobileMenuOpen ? {display: 'none'} : {display: "flex"}}>
        <div className="bars ">
          <FaBars onClick={() => setMobileMenuOpen(true)} />
        </div>
      </div>
    </header>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { logout })(Header);
