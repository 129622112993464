import Helmet from "react-helmet";
import { connect } from "react-redux";
import Header from "./Header";
import Dashboard from "./Dashboard";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
// import Announcement from "./Announcement";
import "../styles/header.css";
import { useEffect, useState } from "react";

const Layout = ({ user: { isAuthenticated }, title, description, keywords, children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const location = useLocation()
  useEffect(()=> {
    setMobileMenuOpen(false)
    window.scrollTo(0, 0);
  }, [location.pathname])
  return (
    <div className="reb_wrapper">
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="locale" content="IN" />
        <meta name="language" content="en" />
        <meta name="country" content="IN" />
        <link rel="icon" href="logo.png" />
        <meta name="theme-color" content="#000000" />
        <meta property="og:type" content="website" />
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://rebellionesports.gg/static/media/logo.jpg"
        />
        <meta property="og:url" content="https://rebellionesports.gg" />
        <link rel="canonical" href="https://rebellionesports.gg" />
      </Helmet>
      <div className={ isAuthenticated ? 'main' : ''} >
       {isAuthenticated && <Dashboard mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen = {setMobileMenuOpen} />}
       {isAuthenticated &&  <div></div>}
        <div className="content_wrapper" onClick={()=> mobileMenuOpen && setMobileMenuOpen(false)}>
          <Header mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen = {setMobileMenuOpen} />
          {/* <Announcement/> */}
          {children}
          <Footer />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Layout);
