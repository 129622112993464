import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'

const Team = ({user: {token}}) => {

    const { teamid } = useParams()
    const [teamdata, setteamdata] = useState(null)

    const navigate = useNavigate()


    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'rebellion/teams?teamid=' + teamid, config).then(res => {
            setteamdata(res.data[0])
        })
    }, [teamid, token])

    const updateteam = (e, key) => {
        let tempteam = {...teamdata}
        tempteam[key] = e.target.value
        setteamdata(tempteam)
    }

    const updateplayers = (index, e, key) => {
        let tempteam = {...teamdata}
        tempteam.players[index][key] = e.target.value
        setteamdata(tempteam)
    }

    const submitteam = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios.post(process.env.REACT_APP_KC_API_URL + 'rebellion/teams?teamid=' + teamid, config).then(res =>
            navigate("/players")
        )
    }

    const cancelupdates = () => {
        navigate("/players")
    }

  return (
        <div className='mx-width' style={{minHeight: 'calc(100vh - 450px)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div className='players'>
              <p className='heading'>Team</p>
                {teamdata !== null &&
                <div className='team_players'>
                    <div className='team'>
                        <table className="border even_odd" cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td>Team</td>
                                    <td><input value={teamdata.name} onChange = {(e) => updateteam(e, "name")} /></td>
                                </tr>
                                <tr>
                                    <td>Coach</td>
                                    <td><input value={teamdata.coach} onChange = {(e) => updateteam(e, "coach")} /></td>
                                </tr>
                            </tbody>
                        </table>
                        <table  className="border even_odd" cellPadding="0" cellSpacing="0">
                            <tbody>
                            {teamdata["players"].map((player, j) =>
                            <>
                                <tr key={j}>
                                    <td>Type</td>
                                    <td>
                                    {player.type === "leader" ? (
                                        "Leader"
                                    ) : (
                                        "Player"
                                    )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Name</td>
                                    <td><input value={player.name} onChange = {(e) => updateplayers(j, e, "name")} /></td>
                                </tr>
                                <tr>
                                    <td>ROIT ID</td>
                                    <td><input value={player.riotid} onChange = {(e) => updateplayers(j, e, "riotid")} /></td>
                                </tr>
                                 <tr>
                                    <td>Rank</td>
                                    <td><input value={player.rank} onChange = {(e) => updateplayers(j, e, "rank")} /></td>
                                </tr>
                                <tr>
                                    <td>Mobile No</td>
                                    <td><input value={player.mobile} onChange = {(e) => updateplayers(j, e, "mobile")} /></td>
                                </tr>
                                </>
                                )}
                            </tbody>
                        </table>
                        <div className='btns_container'>
                            <button className='btn' onClick={submitteam}>Submit</button>
                            <button className='btn' onClick={cancelupdates}>Cancel</button>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user
})
export default connect(mapStateToProps) (Team)