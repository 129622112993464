import "../styles/dashboard.css";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../actions/user";
import { GrClose } from "react-icons/gr";
import { GiVrHeadset } from "react-icons/gi";

import {
  FaHome,
  FaGamepad,
  FaRegUser,
  // FaCrown,
  // FaRegIdCard,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Dashboard = ({
  user: { isAuthenticated, userDetails, redirectTo },
  logout,
  mobileMenuOpen,
  setMobileMenuOpen,
}) => {
  const navigateTo = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveTab(location.pathname);
    } else if (location.pathname === "/players") {
      setActiveTab(location.pathname);
    } else if (location.pathname === "/registration") {
      setActiveTab(location.pathname);
    } else if (location.pathname === "/tournament") {
      setActiveTab(location.pathname);
    }
  }, [location.pathname]);
  const menuItem = [
    {
      path: "/",
      name: "Home",
      icon: <FaHome />,
      active: activeTab === "/" ? true : false,
    },
    {
      path: "/players",
      name: "Players",
      icon: <GiVrHeadset />,
      active: activeTab === "/players" ? true : false,
    },
    {
      path: "/registration",
      name: "Registration",
      icon: <FaRegUser />,
      active: activeTab === "/registration" ? true : false,
    },
    {
      path: "/tournament",
      name: "Tournament",
      icon: <FaGamepad />,
      active: activeTab === "/tournament" ? true : false,
    },
    // {
    //     path:"/touramentreg",
    //     name:"Registered Tournaments",
    //     icon:<FaRegIdCard/>
    // },
    // {
    //     path:"/winners",
    //     name:"Winners",
    //     icon:<FaCrown/>
    // }
  ];

  const dashHandler = (path) => {
    document.getElementById("header").classList.toggle("hb-open");
    document.getElementById("header").classList.remove("hb-open");
    document.body.classList.remove("hb-open");
    navigateTo(path);
    <img className="top-section-bg" alt="Rebellion Esports" src="logo.png" />;
  };
  const logoutHandler = () => {
    logout();
    navigateTo("login");
  };

  return (
    <div className={`sidebar ${mobileMenuOpen ? "open" : ""}`}>
      <div>
        <div className="bars">
          <GrClose onClick={() => setMobileMenuOpen(false)} />
        </div>
        <div className="top_section">
          <h1>REBELLION</h1>
          <p>eSports</p>
          <img
            className="top-section-bg"
            alt="Rebellion Esports"
            src="logo.png"
          />
        </div>
      </div>
      {isAuthenticated && (
        <>
          <div className="link-wrapper">
            {menuItem.map((item, index) => (
              <p
                onClick={() => dashHandler(item.path)}
                to={item.path}
                key={index}
                className={`link ${item.active ? "tab-active" : ""}`}
              >
                <div className="icon tooltip">
                  {item.icon}
                  <span class="tooltiptext">{item.name}</span>
                </div>
                <div className="link_text">{item.name}</div>
              </p>
            ))}
          </div>
          <div className="item account mob">
            <ul className="sitenav">
              <div className="dashboard-profile">
                <div className="med">
                  <svg
                    fill="rgb(240, 204, 237)"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2c2.757 0 5 2.243 5 5.001 0 2.756-2.243 5-5 5s-5-2.244-5-5c0-2.758 2.243-5.001 5-5.001zm0-2c-3.866 0-7 3.134-7 7.001 0 3.865 3.134 7 7 7s7-3.135 7-7c0-3.867-3.134-7.001-7-7.001zm6.369 13.353c-.497.498-1.057.931-1.658 1.302 2.872 1.874 4.378 5.083 4.972 7.346h-19.387c.572-2.29 2.058-5.503 4.973-7.358-.603-.374-1.162-.811-1.658-1.312-4.258 3.072-5.611 8.506-5.611 10.669h24c0-2.142-1.44-7.557-5.631-10.647z"></path>
                  </svg>
                </div>
                <div className="cnt">
                  <p>{userDetails.name}</p>
                  <p>{userDetails.user_status}</p>
                </div>
              </div>
            </ul>
            <div className="logout" onClick={logoutHandler}>
              <div>
                <svg width="30px" height="25px" viewBox="0 0 24 24" fill="none">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8m4-9-4-4m4 4-4 4m4-4H9"
                  />
                </svg>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { logout })(Dashboard);
