import KuroLink from "../components/KuroLink";
import valoBg from "../assets/img/valobg.png";
import rebellionType from "../assets/img/rebellion-typo2.png";

import "../styles/home.css";

const Home = () => {
  
  return (
    <div className="home">
      <div className="banner_text home_banner  ">
        <div className="home_banner_info">
        <img src={rebellionType}  alt="" />
          {/* <h3>Join The <b>Rebellion</b>  eSports Tournament Now</h3> */}
          <p>Experience the ultimate thrill of competitive gaming like never before! 
            Get ready to embark on an adrenaline-fueled journey filled with intense battles,
            fierce rivalries, and unforgettable moments that will push your gaming skills to the limit.
            </p>
          <div className="banner_btns">
            <KuroLink classList="txt-link" to="/registration">
              <button className="banner_btn">Register Player/Team</button>
            </KuroLink>
            <KuroLink classList="txt-link" to="/tournament">
              <button className="banner_btn">Register for Tournament</button>
            </KuroLink>
          </div>
        </div>
        <div className="home_banner_img">
        <img  src={valoBg} alt=""/>
        </div>
      </div>
    </div>
  );
};

export default Home;
