import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import KuroLink from "../components/KuroLink";
import { updateLoginMsg, verifyUserid, register } from "../actions/user";
import rebBg from "../assets/img/regnewbg.jpg";
import "../styles/userauth.css";

const Signup = () => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpsent, setOtpsent] = useState(false);
  const [flag, setFlag] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [hover, setHover] = useState(false);

  const { isAuthenticated, loginMsg } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      if (flag) {
        if (loginMsg === "otp sent") {
          setOtpsent(true);
          dispatch(updateLoginMsg(""));
        }
      } else {
        setFlag(true);
        dispatch(updateLoginMsg(""));
      }
    }
  }, [isAuthenticated, loginMsg, flag, dispatch]);

  const focusHandler = (e) => {
    e.target.closest(".field_wrapper").classList.add("focus");
  };

  const blurHandler = (e) => {
    e.target.closest(".field_wrapper").classList.remove("focus");
    if (e.target.value === "") {
      e.target.closest(".field_wrapper").classList.remove("filled");
    } else {
      e.target.closest(".field_wrapper").classList.add("filled");
    }
  };

  const nameHandler = (e) => {
    e.target.closest(".field_wrapper").classList.remove("warn");
    if (e.target.value === "" || /^[a-zA-Z ]+$/.test(e.target.value)) {
      setName(e.target.value);
    }
  };

  const phoneHandler = (e) => {
    e.target.closest(".field_wrapper").classList.remove("warn");
    if (
      e.target.value === "" ||
      (/^[0-9]+$/.test(e.target.value) && e.target.value.length <= 10)
    ) {
      setPhone(e.target.value);
    }
    changeHandler(e);
  };

  const changeHandler = (e) => {
    e.target.closest(".field_wrapper").classList.remove("warn");
    if (e.target.value !== "") {
      e.target.closest(".field_wrapper").classList.add("filled");
    }
  };

  const pwdHandler = (e) => {
    document
      .querySelector(".signup #pwd")
      .closest(".field_wrapper")
      .classList.remove("warn");
    document
      .querySelector(".signup #confirmpwd")
      .closest(".field_wrapper")
      .classList.remove("warn");
    changeHandler(e);
  };

  const pwdToggleHandler = (e) => {
    if (e.target.closest(".field_wrapper").classList.contains("show_pwd")) {
      e.target.closest(".field_wrapper").classList.remove("show_pwd");
      e.target
        .closest(".field_wrapper")
        .querySelector("#pwd")
        .setAttribute("type", "password");
    } else {
      e.target.closest(".field_wrapper").classList.add("show_pwd");
      e.target
        .closest(".field_wrapper")
        .querySelector("#pwd")
        .setAttribute("type", "text");
    }
  };

  const otpHandler = (e) => {
    e.target.closest(".field_wrapper").classList.remove("warn");
    if (
      e.target.value === "" ||
      (/^[0-9]+$/.test(e.target.value) && e.target.value.length <= 6)
    ) {
      setOtp(e.target.value);
    }
  };

  const VerifyHandler = () => {
    let proceed = true;
    const name = document.querySelector(".signup #name");
    const phone = document.querySelector(".signup #phone");
    const pwd = document.querySelector(".signup #pwd");
    const confirmpwd = document.querySelector(".signup #confirmpwd");
    const email = document.querySelector(".signup #email");
    const username = document.querySelector(".signup #username");
    if (name.value === "") {
      name.closest(".field_wrapper").classList.add("warn");
      proceed = false;
    }
    if (phone.value === "" || phone.value.length < 10) {
      phone.closest(".field_wrapper").classList.add("warn");
      proceed = false;
    }
    if (pwd.value.length < 8) {
      pwd.closest(".field_wrapper").classList.add("warn");
      proceed = false;
    }
    if (pwd.value === confirmpwd.value) {
      setPwd(confirmpwd.value);
    } else {
      confirmpwd.closest(".field_wrapper").classList.add("warn");
      proceed = false;
    }
    if (!/(?!.*\.\.)(^[^.][^@\s]+@[^@\s]+\.[^@\s.]+$)/.test(email.value)) {
      email.closest(".field_wrapper").classList.add("warn");
      proceed = false;
    } else {
      setEmail(email.value);
    }
    if (username.value !== "" && !/^[a-zA-Z0-9_@.]+$/.test(username.value)) {
      username.closest(".field_wrapper").classList.add("warn");
      proceed = false;
    } else {
      setUsername(username.value);
    }
    if (proceed) {
      dispatch(verifyUserid(phone.value, email.value, username.value));
    }
  };

  const backHandler = () => {
    setName("");
    setUsername("");
    setPhone("");
    setEmail("");
    setOtp("");
    setOtpsent(false);
    dispatch(updateLoginMsg(""));
  };

  const signUpHandler = () => {
    dispatch(register(username, email, phone, pwd, name, otp));
  };
  const handleMouseMove = (e) => {
    const { left, top } = e.target.getBoundingClientRect();
    const x = e.pageX - left + 400;
    const y = e.pageY - top;
    e.target.style.setProperty("--mouse-x", `${x}px`);
    e.target.style.setProperty("--mouse-y", `${y}px`);
    const tempPos = { x: x, y: y };
    setMousePos(tempPos);
    setHover(true);
  };

  return (
    <>
      {isAuthenticated && <Navigate to="/" />}
      <div className="formheight">
        <div
          className="user_auth signup"
          onMouseMove={(e) => handleMouseMove(e)}
          onMouseLeave={() => {
            setHover(false);
          }}
          style={
            hover
              ? {
                background: `radial-gradient(600px circle at ${mousePos.x}px ${mousePos.y}px, rgba(168, 111, 165, 1), transparent 90%)`,
              }
              : { background: "none" }
          }
        >
          <div className="user-auth-border">
            <div className="login-reb-logo">
              <div className="med">
                <a href="https://rebellionesports.gg/" target="blank">
                  <img src="logo.png" alt="logo"></img>
                </a>
              </div>
              <div className="title txt-dark-head">
                <h1>
                  REBILLION <p>eSports</p>{" "}
                </h1>
              </div>
              <img className="login-reb-img" src={rebBg} alt="" />
            </div>
          <div className="auth_details auth_signup_details">
            {!otpsent ? (
              <div className="login_details">
              <h2>
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <path d="M6 8v-2c0-3.313 2.687-6 6-6 3.312 0 6 2.687 6 6v2h-2v-2c0-2.206-1.795-4-4-4s-4 1.794-4 4v2h-2zm-3 2v14h18v-14h-18z" />
                </svg>
                <span>SignUp</span>
              </h2>
                <div className="field_wrapper">
                  <label>Name*</label>
                  <div className="input_wrapper">
                    <input
                      id="name"
                      name="name"
                      value={name}
                      onFocus={focusHandler}
                      onBlur={blurHandler}
                      onChange={nameHandler}
                    />
                    <span className="warning">Enter your name</span>
                  </div>
                </div>
                <div className="field_wrapper">
                  <label>Phone Number*</label>
                  <div className="input_wrapper">
                    <input
                      id="phone"
                      name="phone"
                      value={phone}
                      onFocus={focusHandler}
                      onBlur={blurHandler}
                      onChange={phoneHandler}
                    />
                    <span className="warning">Enter a valid phone number</span>
                  </div>
                </div>
                <div className="field_wrapper">
                  <label>Password</label>
                  <div className="input_wrapper">
                    <input
                      id="pwd"
                      name="pwd"
                      type="password"
                      onFocus={focusHandler}
                      onBlur={blurHandler}
                      onChange={pwdHandler}
                    />
                    <div className="toggle_pwd">
                      <svg
                        className="show"
                        onClick={pwdToggleHandler}
                        title="Show password"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 5c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zm0-2c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z" />
                      </svg>
                      <svg
                        className="hide"
                        onClick={pwdToggleHandler}
                        title="Hide password"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z" />
                      </svg>
                    </div>
                    <span className="warning">
                      Password must be atleast 8 characters
                    </span>
                  </div>
                </div>
                <div className="field_wrapper">
                  <label>Confirm Password</label>
                  <div className="input_wrapper">
                    <input
                      id="confirmpwd"
                      name="confirmpwd"
                      type="password"
                      onFocus={focusHandler}
                      onBlur={blurHandler}
                      onChange={pwdHandler}
                    />
                    <span className="warning">Password does not match</span>
                  </div>
                </div>
                <div className="field_wrapper">
                  <label>Email Address*</label>
                  <div className="input_wrapper">
                    <input
                      id="email"
                      name="email"
                      onFocus={focusHandler}
                      onBlur={blurHandler}
                      onChange={changeHandler}
                    />
                    <span className="warning">Enter a valid Email Address</span>
                  </div>
                </div>
                <div className="field_wrapper">
                  <label>Username (Optional)</label>
                  <div className="input_wrapper">
                    <input
                      id="username"
                      name="username"
                      onFocus={focusHandler}
                      onBlur={blurHandler}
                    />
                    <span className="warning">
                      Enter a valid username. Username may contain alphabets,
                      numbers and `@`,`_`,`.`
                    </span>
                  </div>
                </div>
                <div className="field_wrapper">
                  <button
                    className="btn pri1 solid"
                    name="continue"
                    id="continue"
                    onClick={VerifyHandler}
                  >
                    Continue
                  </button>
                  {loginMsg !== "" && loginMsg !== "otp sent" && (
                    <span className="login_error">{loginMsg}</span>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="otp_details">
                  <span className="back" onClick={backHandler}>
                    <svg
                      fill="#4d4c4c"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M0 12l9-8v6h15v4h-15v6z" />
                    </svg>
                  </span>
                  <p>Enter OTP sent to your mobile number</p>
                  <div className="field_wrapper">
                    <label>OTP</label>
                    <div className="input_wrapper">
                      <input
                        id="otp"
                        name="otp"
                        value={otp}
                        onFocus={focusHandler}
                        onBlur={blurHandler}
                        onChange={otpHandler}
                      />
                    </div>
                  </div>
                  <div className="field_wrapper">
                    <button
                      className="btn pri1 solid"
                      name="signup"
                      id="signup"
                      onClick={signUpHandler}
                    >
                      Signup
                    </button>
                    {loginMsg !== "" && (
                      <span className="login_error">{loginMsg}</span>
                    )}
                  </div>
                </div>
                <p className="util">
                  By clicking Sign Up, you are indicating that you have read and
                  you accept our{" "}
                  <KuroLink href="terms-of-service">Terms of Service</KuroLink>{" "}
                  and <KuroLink href="privacy-policy">Privacy Policy</KuroLink>.
                </p>
              </>
            )}
            <p className="util" style={{width: '80%',textAlign: "right"}}>
              Existing User?<KuroLink to="/login"> Log in</KuroLink>
            </p>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
