import React from 'react'
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'

const Player = ({user: {token}}) => {

    const { playerid } = useParams()
    const [playerdata, setplayerdata] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'rebellion/players?playerid=' + playerid, config).then(res => {
            setplayerdata(res.data[0])
        })
    }, [playerid, token])

    const updateplayer = (e, key) => {
        let tempplayer = {...playerdata}
        tempplayer[key] = e.target.value
        setplayerdata(tempplayer)
    }


    const submitplayer = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios.post(process.env.REACT_APP_KC_API_URL + 'rebellion/players?playerid=' + playerid, config).then(res =>
            navigate("/players")
        )
    }

    const cancelupdates = () => {
        navigate("/players")
    }

  return (
    <div className='mx-width' style={{minHeight: 'calc(100vh - 450px)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div className='players'>
            {playerdata !== null &&
                <div className='player'>
                    <p className='heading'>Solo Players</p>
                    <table className="border even_odd" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td>Name</td>
                                <td><input value={playerdata.name} onChange = {(e) => updateplayer(e, "name")} /></td>
                            </tr>
                            <tr>
                                <td>ROIT ID</td>
                                <td><input value={playerdata.riotid} onChange = {(e) => updateplayer(e, "riotid")} /></td>
                            </tr>
                            <tr>
                                <td>Rank</td>
                                <td><input value={playerdata.rank} onChange = {(e) => updateplayer(e, "rank")} /></td>
                            </tr>
                            <tr>
                                <td>Mobile No</td>
                                <td><input value={playerdata.mobile} onChange = {(e) => updateplayer(e, "mobile")} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='btns_container'>
                        <button className='btn' onClick={submitplayer}>Submit</button>
                        <button className='btn' onClick={cancelupdates}>Cancel</button>
                    </div>
                </div>
                }
                
        </div>
    </div>
  )
}

const mapStateToProps = state => ({
    user: state.user
})
export default  connect(mapStateToProps) (Player)