import { useState} from "react"
import { useNavigate } from 'react-router-dom'
import { connect} from 'react-redux'
import axios from 'axios'
import "../styles/register.css"

import registerBg from '../assets/img/regnewbg.jpg'
import rebtypo from '../assets/img/rebellion-typo2.png'

const Registrationform = ({user: {token}}) => {

    const [errmsgsolo, seterrmsgsolo] = useState("")
    const [errmsgteam1, seterrmsgteam1] = useState("")
    const [errmsgteam2, seterrmsgteam2] = useState("")

    const defteam = {
        "name": "",
        "coach": "",
        "players": [
            {
                "type": "leader",
                "name": "",
                "riotid": "",
                "rank": "",
                "mobile": ""
            },
            {
                "type": "player",
                "name": "",
                "riotid": "",
                "rank": "",
                "mobile": ""
            }
        ]
    }

    let navigate = useNavigate()

    const [type, settype] = useState("")
    const [teamsize, setteamsize] = useState(2)
    const [team, setteam] = useState(defteam)
    const [soloplayer, setsoloplayer] = useState(
        {
            "name": "",
            "riotid": "",
            "rank": "",
            "mobile": ""
        }
    )

    const toggleForm = (type) => {
        let fws = document.getElementsByClassName("form_wrapper")[0].getElementsByClassName("field_wrapper")
        document.querySelector('.form_wrapper').scroll({top:0,behavior:'smooth'})
        for (let i=0; i<fws.length; i++) {
            fws[i].classList.remove("focus")
            fws[i].classList.remove("filled")
        }
        settype(type)
        setteam(defteam)
        seterrmsgteam1("")
        seterrmsgsolo("")
        setteamsize(2)
        setsoloplayer({
            "name": "",
            "riotid": "",
            "rank": "",
            "mobile": ""
        })
    }

    const focusHandler = (e) => {
        e.target.closest(".field_wrapper").classList.add("focus");
        e.target.closest(".input_wrapper").classList.remove("input_hover")
    }

    const blurHandler = (e) => {
        e.target.closest(".field_wrapper").classList.remove("focus")
        if (e.target.value === "") {
            e.target.closest(".field_wrapper").classList.remove("filled")
        } else {
            e.target.closest(".field_wrapper").classList.add("filled")
        }
    }

    const updateteam = (key, e) => {
        let tempteam = {...team}
        e.target.closest(".field_wrapper").classList.remove("warn")
        if (e.target.value === "" || /^[a-zA-Z ]+$/.test(e.target.value)) {
            tempteam[key] = e.target.value
        }
        setteam(tempteam)
        seterrmsgteam1("")
    }

    const teamformHandler = (e) => {
        let proceed = true
        if (team.name === "" ||team.players[0].name === "" || team.players[0].riotid === "" || team.players[0].rank === "" || team.players[0].mobile === "") {
            proceed = false
        }
        if(proceed)
        document.querySelector('.form_wrapper').scroll({top: 9000,behavior:'smooth'})
        else {
            seterrmsgteam1("*Please Enter Team details")
        }
    }

    const backHandler = (e) => {
        document.querySelector('.form_wrapper').scroll({top:0,behavior:'smooth'})
        setteamsize(2)
    }

    const teamHandler = (mem) => {
        setteamsize(mem)
        let tempteam = {...team}
        if (tempteam.players.length < mem) {
            for (let i=tempteam.players.length; i<mem; i++) {
                tempteam.players.push({"type": "player", "name": "", "riotid": "", "rank": "", "mobile": ""})
            }
        } else if (tempteam.players.length > mem) {
            for (let i=tempteam.players.length; i>mem; i--) {
                tempteam.players.pop()
            }
        }
        setteam(tempteam)
    }

    const updateteammember = (index, key, e) => {
        let tempteam = {...team}
        if(key === "mobile"){
            if (e.target.value === "" || ((/^[0-9]+$/).test(e.target.value) && e.target.value.length <= 10)) {
                tempteam["players"][index][key] = e.target.value.trim()
            }
        } else {
            tempteam["players"][index][key] = e.target.value.trim()
        }
        setteam(tempteam)
        seterrmsgteam2("")
        seterrmsgteam1("")
    }

    const updateSoloPlayer = (key, e) => {
        let tempsoloplayer = {...soloplayer}
        if(key === "mobile"){
            if (e.target.value === "" || ((/^[0-9]+$/).test(e.target.value) && e.target.value.length <= 10)) {
                tempsoloplayer[key] = e.target.value.trim()
            }
        } else {
            tempsoloplayer[key] = e.target.value.trim()
        }
        setsoloplayer(tempsoloplayer)
        seterrmsgsolo("")
    }

    const registerteam = () => {
            let proceed = true
            if (team.name === "") {
                proceed = false
            }
        for (let player of team.players) {
            if (player.name === "" || player.riotid === "" || player.rank === "" || player.mobile === "") {
                proceed = false
            }
        }
        if (proceed) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }
            axios.post(process.env.REACT_APP_KC_API_URL + 'rebellion/teams', team, config)
            return navigate("/tournament")
        } else {
            seterrmsgteam2("*Please Enter All The Details")
        }
    }

    const registerplayer = () => {
        let name = document.querySelector('.solo_form #name').value
        let riotid = document.querySelector('.solo_form #riotid').value
        let rank = document.querySelector('.solo_form #rank').value
        let mobile = document.querySelector('.solo_form #mobile').value
        if (name !== "" && riotid !== "" && rank !== "" && mobile !== "") {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }
            axios.post(process.env.REACT_APP_KC_API_URL + 'rebellion/players', soloplayer, config)
            return navigate("/tournament")
        } else {
            seterrmsgsolo("*Please Enter Solo Details")
        }
    }

    return (
        <div className="maincontainer regform">
          <div className="form_box_border">
            <div className={"form_box " + type}>
            <img src={registerBg} className="form_bg" alt="" />
                <div className="heading">
                            <img src={rebtypo} className="rebtypo" alt="" />
                    <p>Registration Mode</p>
                <div className="btn_box">
                    <div className="sliderbg"></div>
                    <button className="sliderbtn" onClick={(e) => toggleForm("")}>Team</button>
                    <button className="sliderbtn" onClick={(e) => toggleForm("solo")}>Solo</button>
                </div>
                </div>
                <div className="container">
                    <div className="form_wrapper">
                        <div className="form team_form">
                                <div className="team_details">
                                    <h1>Enter Team Details</h1>
                                    <div className="teamleader">
                                        <div className="field_wrapper">
                                            <label>Team Name</label>
                                            <div className="input_wrapper">
                                                <input type="text" className="input_field" value={team.name} onFocus={focusHandler} onBlur={blurHandler} onChange={(e) => updateteam("name", e)} />
                                             </div>
                                        </div>
                                        <div className="field_wrapper">
                                            <label>Coach Name (Optional)</label>
                                            <div className="input_wrapper">
                                                <input type="text" className="input_field" value={team.coach} onFocus={focusHandler} onBlur={blurHandler} onChange={(e) => updateteam("coach", e)} />
                                            </div>
                                        </div>
                                        <div className="field_wrapper">
                                            <label>Leader Name</label>
                                            <div className="input_wrapper">
                                                <input type="text" value={team.players[0].name} className="input_field" onFocus={focusHandler} onBlur={blurHandler} onChange={(e) => updateteammember(0, "name", e)} />
                                            </div>
                                        </div>
                                        <div className="field_wrapper">
                                            <label>Leader RIOT ID</label>
                                            <div className="input_wrapper">
                                                <input type="text" value={team.players[0].riotid} className="input_field" onFocus={focusHandler} onBlur={blurHandler} onChange={(e) => updateteammember(0, "riotid", e)} />
                                            </div>
                                        </div>
                                        <div className="field_wrapper">
                                            <label>Leader Rank</label>
                                            <div className="input_wrapper">
                                                <input type="text" value={team.players[0].rank} className="input_field" onFocus={focusHandler} onBlur={blurHandler} onChange={(e) => updateteammember(0, "rank", e)}/>
                                            </div>
                                        </div>
                                        <div className="field_wrapper">
                                            <label>Leader Mobile Number</label>
                                            <div className="input_wrapper">
                                                <input type="text" value={team.players[0].mobile} className="input_field" onFocus={focusHandler} onBlur={blurHandler}  onChange={(e) => updateteammember(0, "mobile", e)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn_container">
                                        <button type="submit" className="continuebtn" onClick={teamformHandler}>Continue</button>
                                        <button type="submit" className="submit_btn backbtn" onClick={backHandler}>Back</button>
                                    </div>
                                    <div className="error_msg_cont">
                                        {errmsgteam1 !== "" && <span className="error_msg">{errmsgteam1}</span>}
                                    </div>
                                </div>
                                <div className="team_members_details">
                                    <div className="teammemberheading">
                                        <h1>Enter Your Team Members Details</h1>
                                    </div>
                                    <div className="teammemberselect">
                                        <p>Select Number of Members including Leader:</p>
                                        <select value={teamsize} onChange={(e) => teamHandler(e.target.value)}>
                                            {[2,3,4,5].map((mem, i) =>
                                            <option key={i} value={mem}>{mem}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="teammembersform">
                                        {team.players.map((member, i) =>
                                        member.type !== "leader" && 
                                        <div className="team_member" key={i}>
                                            <div className="field_wrapper">
                                                <label>Name</label>
                                                <div className="input_wrapper">
                                                    <input type="text" value={member.name} className="input_field" onFocus={focusHandler} onBlur={blurHandler} onChange={(e) => updateteammember(i, "name", e)} />
                                                </div>
                                            </div>
                                            <div className="field_wrapper">
                                                <label>RIOT ID</label>
                                                <div className="input_wrapper">
                                                    <input type="text" value={member.riotid} className="input_field" onFocus={focusHandler} onBlur={blurHandler} onChange={(e) => updateteammember(i, "riotid", e)} />
                                                </div>
                                            </div>
                                            <div className="field_wrapper">
                                                <label>Rank</label>
                                                <div className="input_wrapper">
                                                    <input type="text" value={member.rank} className="input_field" onFocus={focusHandler} onBlur={blurHandler} onChange={(e) => updateteammember(i, "rank", e)} />
                                                </div>
                                            </div>
                                            <div className="field_wrapper">
                                                <label>Mobile No.</label>
                                                <div className="input_wrapper">
                                                    <input type="text" value={member.mobile} className="input_field" onFocus={focusHandler} onBlur={blurHandler} onChange={(e) => updateteammember(i, "mobile", e)} />
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                    <div className="btn_container">
                                        <button type="submit" onClick={registerteam}>Register Now</button>
                                        <button type="submit" onClick={backHandler}>Back</button>
                                    </div>
                                    <div className="error_msg_cont">
                                        {errmsgteam2 !== "" && <span className="error_msg">{errmsgteam2}</span>}
                                    </div>
                                    </div>
                                </div>
                        </div>
                        <div className="form solo_form">
                            <div className="solo_field">
                                <h1>Enter Player Details</h1>
                                <div className="field_wrapper">
                                    <label>Enter Your Name</label>
                                    <div className="input_wrapper">
                                        <input id ="name" type="text" value={soloplayer.name} className="input_field" onFocus={focusHandler} onBlur={blurHandler} onChange={(e) => updateSoloPlayer("name", e)} />
                                    </div>
                                </div>
                                <div className="field_wrapper">
                                    <label>Enter RIOT ID</label>
                                    <div className="input_wrapper">
                                        <input id ="riotid" type="text" value={soloplayer.riotid} className="input_field" onFocus={focusHandler} onBlur={blurHandler} onChange={(e) => updateSoloPlayer("riotid", e)} />
                                    </div>
                                </div>
                                <div className="field_wrapper">
                                    <label>Enter Rank</label>
                                    <div className="input_wrapper">
                                        <input id ="rank" type="text" value={soloplayer.rank} className="input_field" onFocus={focusHandler} onBlur={blurHandler} onChange={(e) => updateSoloPlayer("rank", e)} />
                                    </div>
                                </div>
                                <div className="field_wrapper">
                                    <label>Enter Mobile Number</label>
                                    <div className="input_wrapper">
                                        <input id ="mobile" type="text" value={soloplayer.mobile} className="input_field" onFocus={focusHandler} onBlur={blurHandler} onChange={(e) => updateSoloPlayer("mobile", e)} />
                                    </div>
                                </div>
                            <div className= "btn_container">
                                <button type="submit" className="" onClick={registerplayer}>Register Now</button>
                            </div>
                            <div className="error_msg_cont">
                                {errmsgsolo !== "" && <span className="error_msg">{errmsgsolo}</span>}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps, { })(Registrationform)