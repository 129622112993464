import { useEffect} from "react"
import { connect } from 'react-redux'
import axios from 'axios'

const Winners = ({user: {token}}) => {

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios.post(process.env.REACT_APP_KC_API_URL + 'rebellion/winners', config).then(res => {
        })
    }, [token])
    
  return (
        <div className="maincontainer mx-width">
            <div className="title_container">
                <p className="title">Tournament Winners</p>
                <div className="winner_details">
                    <table className="border even_odd" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td>Winner Team</td>
                                <td>Tournament Date</td>
                                <td>Game Names</td>
                            </tr>
                            <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    user: state.user
})
export default connect(mapStateToProps)(Winners)