import { useState, useEffect} from "react"
import { connect } from 'react-redux'
import axios from 'axios'
import KuroLink from '../components/KuroLink'
import "../styles/register.css"
import "../styles/tournaments.css"

const Tournament = ({user: {token}}) => {

    const [tournaments, settournaments] = useState(null)
    const [tourdata, settourdata] = useState({
        "tournamentid": "",
        "venue": "",
        "playertype": "Team",
        "teamid": "",
        "playerid": ""
    })

    const [players, setplayers] = useState(null)
    const [teams, setteams] = useState(null)
    const [succsessmsg, setsuccsessmsg] = useState("")
    const [errmsg, seterrmsg] = useState("")

    const dropdownHandler = (e) => {
        e.target.closest(".select").classList.toggle("open")
    }

    const tournamentHandler = (e, key) => {
        let temptourdata = {...tourdata}
        temptourdata[key] = e.target.value
        temptourdata["teamid"] = ""
        temptourdata["playerid"] = ""
        document.getElementsByClassName("tournament")[0].classList.remove("open")
        settourdata(temptourdata)
    }

    const tourdownHandler = (e, key, value) => {
        let temptourdata = {...tourdata}
        temptourdata[key] = value
        settourdata(temptourdata)
        e.target.parentNode.parentNode.classList.remove('open')
        seterrmsg("")
    }

    // const venueHandler = (value) => {
    //     setselectedvenue(value)
    //     document.getElementsByClassName("venue")[0].classList.remove("open")
    // }

    const enrollHandler = () => {
        let proceed = true
        if (tourdata.tournamentid === "" || (tourdata.teamid === "" && tourdata.playerid === "")) {
            proceed = false
        }
        
        if (proceed) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }
            axios.post(process.env.REACT_APP_KC_API_URL + 'rebellion/tourneyregister', tourdata, config).then(res => {
                setsuccsessmsg("Registration Successful")
            })
        } else {
            seterrmsg("*Please Select Tournament & Team/Player")
        }
    }

    const cancleHandler = () => {
        setsuccsessmsg("")
        settourdata({
            "tournamentid": "",
            "venue": "",
            "playertype": "Team",
            "teamid": "",
            "playerid": ""
        })
        
    }

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'rebellion/players', config).then(res => {
            setplayers(res.data)
        })
        axios(process.env.REACT_APP_KC_API_URL + 'rebellion/teams', config).then(res => {
            setteams(res.data)
        })
        axios(process.env.REACT_APP_KC_API_URL + 'rebellion/tournaments', config).then(res => {
            settournaments(res.data)
        })
    }, [token])


    return(
        <div className="maincontainer">
            <div className="title_container mx-width">
                <h1 className="title">Rebellion eSports Gaming Tournament</h1>
                {tournaments !== null && tournaments.length > 0 &&
                <div className="tourdata_conatiner">
                    <div className="dropdown_container">
                        <div className="dropdown">
                            <label>Tournament:</label>
                            <div className="tournament select">
                                {/* <p onClick={dropdownHandler}>{tourdata.tournamentid === "" ? "Select Tournament" : tournaments.filter(tour => tour.tournamentid === tourdata.tournamentid)[0]["name"]}</p>
                                <ul className="list">
                                    {tournaments.map((tournament, i) =>
                                    <li key={i} className="options" onClick={(e) => tourdownHandler(e, "tournamentid", tournament.tournamentid)} value={tournament.tournamentid}>{tournament.name}</li>
                                    )}
                                </ul> */}
                                <select className="select-tournament" onChange={dropdownHandler} value={tourdata.tournamentid === "" ? "Select Tournament" : tourdata.tournamentid}>
                                    <option onClick={(e) => tourdownHandler(e, "tournamentid", '')}  value="">Select Tournament</option>
                                    {tournaments.map((tournament, i) =>
                                    <option key={i} className="options" onClick={(e) => tourdownHandler(e, "tournamentid", tournament.tournamentid)} value={tournament.tournamentid}>{tournament.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        {tourdata.tournamentid !== "" &&
                        <div className="dropdown">
                            <label>Venue:</label>
                            <div className="venue select">
                                {tournaments.filter(tour => tour.tournamentid === tourdata.tournamentid)[0]["venue"]}
                                {/* <p onClick={dropdownHandler}>{tourdata.venue}</p>
                                <ul className="list">
                                {tourdata.tournament !== "" &&
                                <li key={i} className="options" onClick={(e) => tourdownHandler(e, "venue", venue)} value={venue}>{venue}</li>
                                }
                                </ul> */}
                            </div>
                        </div>
                        }
                        {tourdata.tournamentid !== "" &&
                        <div className="timings">
                            <p>Tournament on: <label> {tournaments.filter(tour => tour.tournamentid === tourdata.tournamentid)[0]["tour_date"]} </label></p>
                            <p>Registration Starts: <label> {tournaments.filter(tour => tour.tournamentid === tourdata.tournamentid)[0]["reg_start"]} </label></p>
                            <p>Registration End: <label> {tournaments.filter(tour => tour.tournamentid === tourdata.tournamentid)[0]["reg_end"]} </label></p>
                        </div>
                        }
                    </div>
                    {tournaments !== null &&
                    <div className="game">
                        <div className={"gaming_wrapper" + ((tourdata.tournamentid !== "" && tournaments.filter(tour => tour.tournamentid === tourdata.tournamentid)[0]["game"] === "Valorant") ? " active" : " disable")}>
                            <img src="valorant.png" alt="valorant" height="82px"/>
                            <p>Valorant</ p>
                        </div>
                        <div className={"gaming_wrapper" + ((tourdata.tournamentid !== "" && tournaments.filter(tour => tour.tournamentid === tourdata.tournamentid)[0]["game"] === "CS GO") ? " active" : " disable")}>
                            <img src="csgo.png" alt="csgo" />
                            <p>CS GO</p>
                        </div>
                        <div className={"gaming_wrapper" + ((tourdata.tournamentid !== "" && tournaments.filter(tour => tour.tournamentid === tourdata.tournamentid)[0]["game"] === "Dota 2") ? " active" : " disable")}>
                            <img src="dota2.png" alt="dota2" />
                            <p>Dota 2</p>
                        </div>
                        <div className={"gaming_wrapper" + ((tourdata.tournamentid !== "" && tournaments.filter(tour => tour.tournamentid === tourdata.tournamentid)[0]["game"] === "Rainbow Six") ? " active" : " disable")}>
                            <img src="rainbowsix.png" alt="rainbow six"/>
                            <p>Rainbow Six</p>
                        </div>
                        <div className={"gaming_wrapper" + ((tourdata.tournamentid !== "" && tournaments.filter(tour => tour.tournamentid === tourdata.tournamentid)[0]["game"] === "Overwatch 2") ? " active" : " disable")}>
                            <img src="overwatch.png" alt="voerwatch"/>
                            <p>Overwatch 2</p>
                        </div>
                        <div className={"gaming_wrapper" + ((tourdata.tournamentid !== "" && tournaments.filter(tour => tour.tournamentid === tourdata.tournamentid)[0]["game"] === "Rocket League") ? " active" : " disable")}>
                            <img src="rocketleague.png" alt="rocket"/>
                            <p>Rocket League</p>
                        </div>
                        <div className={"gaming_wrapper" + ((tourdata.tournamentid !== "" && tournaments.filter(tour => tour.tournamentid === tourdata.tournamentid)[0]["game"] === "Age of Empires 2 DE") ? " active" : " disable")}>
                            <img src="ageofempires2.png" alt="age of empires"/>
                            <p>Age of Empires 2 DE</p>
                        </div>
                    </div>
                    }
                </div>
                }
                <div className="enroll_container">
                    <div className="dropdown selectplayer">
                        <label>Select Participant Type</label>
                        <div className="radiogroup">
                            <input type="radio" name="type" value="Team" onClick={(e) => tournamentHandler(e, "playertype")} checked={tourdata.playertype === "Team"}/><label>Team</label>
                            <input type="radio" name="type" value="Player" onClick={(e) => tournamentHandler(e, "playertype")} checked={tourdata.playertype === "Player"}/><label>Player</label>
                        </div>
                    </div>
                    <div className="selectplayers">
                    {tourdata.playertype === "Team" ? (
                        teams !== null && teams.length > 0 ? (
                        <div className="dropdown">
                            <label>Select The Team</label>
                            {/* <div className="teams select">
                                <p onClick={dropdownHandler}>{tourdata.teamid === "" ? "Select Team" : teams.filter(team => team.teamid === tourdata.teamid)[0].name}</p>
                                <ul className="list">
                                    {teams !== null && teams.map((team, i) => 
                                    <li key={i} className="options" onClick={(e) => tourdownHandler(e, "teamid", team.teamid)} value={team.teamid}>{team.name}</li>
                                    )}
                                </ul>
                            </div> */}
                            <select className="select-tournament" onChange={dropdownHandler} value={tourdata.teamid === "" ? "Select Team" : tourdata.teamid}>
                                    <option onClick={(e) => tourdownHandler(e, "teamid", '')}  value="">Select Team</option>
                                    {teams !== null && teams.map((team, i) => 
                                    <option key={i} className="options" onClick={(e) => tourdownHandler(e, "teamid", team.teamid)} value={team.teamid}>{team.name}</option>
                                    )}
                                </select>
                        </div>
                        ) : (
                            <p className="txt-light">*** You don't have any teams registered. Please register your team here: <KuroLink  to ="/registration">Register Team</KuroLink></p>
                        )
                    ) : (
                        players !== null && players.length > 0 ? (
                        <div className="dropdown">
                            <label>Select The Player</label>
                            {/* <div className="player select">
                                <p onClick={dropdownHandler}>{tourdata.playerid === "" ? "Select Player" : players.filter(player => player.playerid === tourdata.playerid)[0].name}</p>
                                <ul className="list">
                                    {players !== null && players.map((player, i) => 
                                    <li key={i} className="options" onClick={(e) => tourdownHandler(e, "playerid", player.playerid)} value={player.playerid}>{player.name}</li>
                                    )}
                                </ul>
                            </div> */}
                              <select className="select-tournament" onChange={dropdownHandler} value={tourdata.playerid === "" ? "Select Player" : tourdata.playerid}>
                                    <option onClick={(e) => tourdownHandler(e, "playerid", '')}  value="">Select Player</option>
                                    {players !== null && players.map((player, i) => 
                                    <option key={i} className="options" onClick={(e) => tourdownHandler(e, "playerid", player.playerid)} value={player.playerid}>{player.name}</option>
                                    )}
                                </select>
                        </div>
                        ) : (
                            <p className="txt-light">*** You don't have any players registered. Please register yourself here: <KuroLink  to ="/registration">Register Player</KuroLink></p>
                        )
                    )}
                    </div>
                    <div className="enrollbtn">
                        <button className="btn" onClick={enrollHandler}>Enroll Now</button>
                    </div>
                    <div className="error_msg_cont">
                        {errmsg !== "" && <span className="error_msg">{errmsg}</span>}
                    </div>
                    {succsessmsg !== "" &&
                    <div className="success_msg_cont">
                        <span className="successmsg">{succsessmsg}</span>
                        <svg class="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
                            <circle class="circle" fill="#5bb543" cx="24" cy="24" r="22"/>
                            <path class="tick" fill="none" stroke="#FFF" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M14 27l5.917 4.917L34 17"/>
                        </svg>
                        <svg className="canclesvg" viewPort="0 0 12 12" version="1.1" onClick={cancleHandler}>
                            <line x1="1" y1="20" x2="20" y2="1" stroke="white" strokeWidth="2"/><line x1="1" y1="1" x2="20" y2="20"  stroke="white" strokeWidth="2"/>
                        </svg>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    user: state.user
})
export default connect(mapStateToProps)(Tournament)