import { useState, useEffect} from "react"
import { connect } from 'react-redux'
import axios from 'axios'

const Tournamentreg = ({user: {token}}) => {

    const [tourdata, settourdata] = useState(null)

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios.post(process.env.REACT_APP_KC_API_URL + 'rebellion/tourneyregister', config).then(res => {
        })
    }, [])

  return (
    <div className="maincontainer">
        <div className="title_container mx-width">
            <p className="title">Registered Tournaments</p>
            <div className="tourdata_conatiner">
                <table className="border even_odd" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Tournament Name</td>
                            <td>Player/Team Name</td>
                            <td>Tournament Date</td>
                        </tr>
                        <tr>
                            <td>{tourdata}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}
const mapStateToProps = state => ({
    user: state.user
})
export default connect(mapStateToProps)(Tournamentreg)