import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import KuroLink from '../components/KuroLink'
import '../styles/players.css'

const Players  = ({user: {token}})  => {

    const [players, setplayers] = useState(null)
    const [teams, setteams] = useState(null)

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'rebellion/players', config).then(res => {
            setplayers(res.data)
        })
        axios(process.env.REACT_APP_KC_API_URL + 'rebellion/teams', config).then(res => {
            setteams(res.data)
        })
    }, [])

    return(
        <div className='mx-width min_height' style={{paddingTop: 100}}>
            <div className='playersdetails'>
                <p>Team/Solo Player Details</p>
            </div>
            {teams !== null && teams.length === 0 && players !== null && players.length === 0 ? (
            <div className="noplayers">
                <p>No Team and Players to Show, Please Register Now</p>
                <KuroLink to="/registration"><button className='btn btnreg'>Register Now</button></KuroLink>
            </div>
            ) : (
            <div className='players'>
                {teams !== null &&
                <div className='team_players'>
                    {teams.map((team, i) =>
                    <div key={i} className='player-card-border'>
                        <span>0{i + 1}</span>
                        <div className="player-card">
                        <p className='heading'>Team/Players</p>
                        <div key={i} className='team'>
                            <table className="border even_odd" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>Team</td>
                                        <td> <label>{team.name}</label> </td>
                                    </tr>
                                    <tr>
                                        <td>Coach</td>
                                        <td><label>{team.coach}</label></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table  className="border even_odd" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    {team["players"].map((player, j) =>
                                    <>
                                        <tr>
                                            <td>Type</td>
                                            <td>
                                            {player.type === "leader" ? (
                                                "Leader"
                                            ) : (
                                                "Player"
                                            )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Name</td>
                                            <td><label>{player.name}</label></td>
                                        </tr>
                                        <tr>
                                            <td>ROIT ID</td>
                                            <td><label>{player.riotid}</label></td>
                                        </tr>
                                        <tr>
                                            <td>Rank</td>
                                            <td><label>{player.rank}</label></td>
                                        </tr>
                                        <tr>
                                            <td>Mobile No</td>
                                            <td><label>{player.mobile}</label></td>
                                        </tr>
                                    </>
                                    )}
                                     <tr>
                                        <td colSpan={2}><KuroLink to={"/team/" + team.teamid}><button className=''>Edit Details</button></KuroLink></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                    )}
                </div>
                }
                {players !== null &&
                <div className='soloplayer'>
                    {players.map((player, i) =>
                        player.teamid === "" &&
                        <div className="player-card-border">
                            <span>0{i + 1}</span>
                    <div className='player-card'>
                        <p className='heading'>Solo Player</p>
                        <table key={i} className="border even_odd" cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td>Name</td>
                                    <td><label>{player.name}</label></td>
                                </tr>
                                <tr>
                                    <td>ROIT ID</td>
                                    <td><label>{player.riotid}</label></td>
                                </tr>
                                <tr>
                                    <td>Rank</td>
                                    <td><label>{player.rank}</label></td>
                                </tr>
                                <tr>
                                    <td>Mobile No</td>
                                    <td><label>{player.mobile}</label></td>
                                </tr>
                                <tr>
                                    <td colSpan={2}><KuroLink to={"/player/" + player.playerid}><button className='btn'>Edit Details</button></KuroLink></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                    )}
                </div>
                }
            </div>
            )}
        </div>
    )
}
const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(Players)