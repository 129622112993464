import { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from '../src/components//Layout'
import { Provider } from 'react-redux'
import store from './store'
import Home from './pages/Home'
import Login from './pages/Login'
import Signup from './pages/Signup'
import './App.css'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import Registrationform from './pages/Registration'
import Tournament from './pages/Tournament'
import Players from './pages/Players'
import Team from './pages/Team'
import Player from './pages/Player'
import Tournamentreg from './pages/Tournamentreg'
import Winners from './pages/Winners'
import NotFoundPage from './pages/NotFoundPage'
import { loadUser } from './actions/user'

const App = () => {
    useEffect(() => {
        store.dispatch(loadUser())
    }, [])

    return (
        <Provider store={store}>
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route path='*' element={<NotFoundPage />} />
                        <Route path ="/" element ={
                            <AuthenticatedRoute>
                                <Home/>
                            </AuthenticatedRoute>
                        } />
                        <Route path="login" element={<Login/>}/>
                        <Route path="signup" element={<Signup/>}/>
                        <Route path="registration" element={
                            <AuthenticatedRoute>
                                <Registrationform/>
                            </AuthenticatedRoute>
                        } />
                        <Route path="tournament" element={ 
                            <AuthenticatedRoute>
                                <Tournament/>
                            </AuthenticatedRoute>
                        } />
                         <Route path="players" element={
                            <AuthenticatedRoute>
                                <Players/>
                            </AuthenticatedRoute>
                        } />
                           <Route path="team/:teamid" element={
                            <AuthenticatedRoute>
                                <Team/>
                            </AuthenticatedRoute>
                        } />
                           <Route path="player/:playerid" element={
                            <AuthenticatedRoute>
                                <Player/>
                            </AuthenticatedRoute>
                        } />
                         <Route path="touramentreg" element={
                            <AuthenticatedRoute>
                                <Tournamentreg/>
                            </AuthenticatedRoute>
                        } />
                         <Route path="winners" element={
                            <AuthenticatedRoute>
                                <Winners/>
                            </AuthenticatedRoute>
                        } />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </Provider>
    )
}

export default App